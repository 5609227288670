import React from "react";
import banner from "../assets/images/webp/blast_banner.webp";

const Footer = () => {
  return (
    <>
      {/* BANNER IMAGE */}
      <div className="d-flex justify-content-center">
        <img
          className="w-100 pt-2 position-relative z-2 footer_banner"
          src={banner}
          alt="blast-banner"
          loading="lazy"
        />
      </div>
      <div className="custom_container container pb-3">
        <p className="text-center text-white opacity_07 fs_xs fw-normal py-sm-5 py-4 mb-0 footer_border_bottom ">
          $BLINKY has no use case till it does 💥
        </p>
      </div>
    </>
  );
};

export default Footer;
