import React from "react"; // Importing React library
import { Flower } from "./common/Icons"; // Importing Flower icon component

const NavBar = () => {
  return (
    <>
      {/* Navigation bar container */}
      <div className="custom_container container py-md-2 position-relative z-3">
        {/* Navigation links */}
        <ul className="d-flex justify-content-sm-center justify-content-between align-items-center gap-sm-5 gap-2 py-md-4 py-3 m-0 p-0 nav_links_parent mx-auto">
          {/* Home link */}
          <li className="d-flex align_items_center gap_20 gap_sm_10 py-1">
            <a
              href="/"
              rel="noopener"
              className="fs_xs text-white fw-normal leading_160 nav_links"
            >
              Home
            </a>
            {/* Flower icon */}
            <span className="li_flower_icons">
              <Flower />
            </span>
          </li>
          {/* About link */}
          <li className="d-flex gap_20 gap_sm_10 align_items_center py-1">
            <a
              href="#about"
              rel="noopener"
              className="fs_xs text-white fw-normal leading_160 nav_links"
            >
              About
            </a>
            {/* Flower icon */}
            <span className="li_flower_icons">
              <Flower />
            </span>
          </li>
          {/* Toknomics link */}
          <li className="d-flex gap_20 gap_sm_10 align_items_center py-1">
            <a
              href="#toknomics"
              rel="noopener"
              className="fs_xs text-white fw-normal leading_160 nav_links"
            >
              Toknomics
            </a>
            {/* Flower icon */}
            <span className="li_flower_icons">
              <Flower />
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
