import React, { useState } from "react";
import BackToTopIcon from "../../assets/images/svg/back_to_top.svg";

const BackToTop = () => {
  // State to manage whether the back to top button should be displayed or not
  const [first, setFirst] = useState(true);

  // Function to scroll back to the top of the page
  const onTop = () => {
    document.documentElement.scrollTop = 0;
  };

  // Event listener to detect scrolling and toggle the display of the back to top button
  window.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 200) {
      // If scrolled down more than 200 pixels, hide the button
      setFirst(false);
    } else {
      // If scrolled less than 200 pixels, show the button
      setFirst(true);
    }
  });

  return (
    <div className="z-3 position-relative">
      {/* Back to top button */}
      <div
        onClick={() => onTop()}
        // Conditional class based on the state to control visibility
        className={`back_to_top position-fixed rounded-pill overflow-hidden border-0 bg_yellow cursor_pointer  d-flex justify-content-center align-items-center ${
          first ? "d-none" : "d-block"
        }`}
      >
        {/* Arrow SVG icon */}
        <img src={BackToTopIcon} alt="back to top icon" className="overflow-hidden"   loading="lazy"  />
      </div>
    </div>
  );
};

export default BackToTop;
