import React, { useState, useRef } from "react";
import videoMp4 from "../assets/video/video.mp4";
import thumbnail from "../assets/images/png/video_thumbnail.png";
import playIcon from "../assets/images/svg/play_icon.svg";
import characterWithGun from "../assets/images/png/hero_character.png";
import { BBullet } from "./common/Icons";

const Video = () => {
  // State to manage whether the video is playing or not
  const [isPlaying, setIsPlaying] = useState(false);

  // Reference to the video element
  const videoRef = useRef(null);

  // Function to toggle play/pause of the video
  const togglePlay = () => {
    const video = videoRef.current;

    if (video.paused || video.ended) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  // Function to handle video end event
  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div id="about" className="mt-lg-5 custom_container container">
      <div className="pb-3 pt-sm-5 pt-4 mt-2">
        <div className="video_border w-100 mb-md-1 mb-3"></div>
        <div className="py-lg-5 my-2 position-relative">
          {/* Video element */}
          <div className="py-1 d-flex justify-content-center">
            <video
              ref={videoRef}
              src={videoMp4}
              poster={thumbnail}
              controls={isPlaying} // Show controls only when playing
              className="video"
              onEnded={handleVideoEnded}
            ></video>
            {/* Play icon shown when video is paused */}
            {!isPlaying && (
              <img
                src={playIcon}
                alt="play icon"
                className="play_icon start-50 top-50 translate-middle position-absolute"
                onClick={togglePlay}
                loading="lazy"
              />
            )}
          </div>
        </div>
        <div className="video_border w-100 mt-3 mb-4"></div>
        <div className="d-flex justify-content-end position-relative">
          {/* Bullet animation */}
          <span className="position-relative bullet_animation">
            <BBullet />
          </span>
          {/* Character with gun image */}
          <img
            className="character_with_gun_animation"
            src={characterWithGun}
            alt="character-with-gun"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
