import React from "react";
import graph from "../assets/images/png/graph.png"; // Importing the graph image
import character_looking_right_side from "../assets/images/png/character_looking_right_side.png";
import character_looking_left_side from "../assets/images/png/character_looking_left_side.png";

// Tokenomics component definition
const Tokenomics = () => {
  return (
    <>
      <div id="toknomics" className="py-3 overflow-hidden">
        {/* Displaying the character image */}
        <img
          className="animation_character left_character_animation"
          src={character_looking_right_side}
          alt="character"
          loading="lazy"
        />
      </div>
      <div className="container custom_container bg_tokenomics mb-4 mb-md-5 mt-sm-4">
        {/* Container for the tokenomics section */}
        {/*<div className="d-flex flex-column flex-lg-row align-items-center justify-content-between w-100">*/}
        <div className="d-flex flex-column align-items-center justify-content-between w-100">
          {/* Container for the graph image */}
          {/*<div className="d-flex justify-content-center justify-content-lg-end">
            <div className="graph_image">
              <img className="w-100" src={graph} alt="graph" loading="lazy" />{" "}
            </div>
          </div>*/}
          {/* Container for tokenomics details */}
          <div className="mt-4 mt-lg-0">
            <h2 className="text_yellow fs_2xl mb-0 text-center text-lg-start">
              Tokenomics
            </h2>
            <div className="col-lg-10 col-xl-9">
              
              <div className="d-flex align-items-center pt-3 justify-content-between ms-2">
                <p className="text-white opacity_07 mb-0 fs_sm">liquidity</p>
                <p className="text_red mb-0 fs_sm">100%</p>
              </div>
              {/*<div className="d-flex align-items-center pt-3 justify-content-between ms-2">
                <p className="text-white opacity_07 mb-0 fs_sm">marketing</p>
                <p className="text_blue mb-0 fs_sm">04%</p>
              </div>
              <div className="d-flex align-items-center pt-3 justify-content-between ms-2">
                <p className="text-white opacity_07 mb-0 fs_sm">airdrop</p>
                <p className="text_blue mb-0 fs_sm">01%</p>
              </div>
              <div className="d-flex align-items-center pt-3 justify-content-between ms-2">
                <p className="text-white opacity_07 mb-0 fs_sm">Liquidity</p>
                <p className="text_yellow mb-0 fs_sm">90%</p>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 overflow-hidden">
        {/* Displaying the character image */}
        <img
          className="animation_character right_character_animation"
          src={character_looking_left_side}
          alt="character"
          loading="lazy"
        />
      </div>
    </>
  );
};

export default Tokenomics; // Exporting the Tokenomics component
