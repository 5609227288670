import gsap from "gsap"; // Importing GSAP library for animation
import React, { useEffect } from "react"; // Importing React and useEffect hook
import heroCharacter from "../assets/images/png/hero_character.png"; // Importing hero character image
//import characterWithGun from "../assets/images/svg/logo.svg"; // Importing character with gun image
import { BCoin } from "./common/Icons"; // Importing BCoin component

const Hero = () => {
    useEffect(() => {
        // Creating animation timeline using GSAP inside useEffect hook
        const showAnimation = gsap.timeline({
            repeat: -1, // Repeating animation infinitely
            duration: 1, // Duration of each animation step
        });

        // Animation for displaying BCoin icons
        showAnimation.fromTo(
            ".b_coin", // Targeting elements with class "b_coin"
            {
                opacity: 0, // Starting opacity
                stagger: 1, // Staggering animation for each element
            },
            { x: 20, opacity: 1, stagger: 1 } // Ending opacity and position
        );

        // Animation for blinking text
        showAnimation.fromTo(
            ".blinky_text", // Targeting elements with class "blinky_text"
            {
                x: 800, // Starting position
                opacity: 1, // Starting opacity
                duration: 10, // Duration of animation
            },
            { x: 0, opacity: 1, duration: 3 } // Ending position and opacity
        );
    }, []); // Running useEffect hook only once when component mounts

    return (
        <div className="position-relative">
            {/* Hero section container */}
            <div id="Hero" className="custom_container container pb-lg-4 mb-lg-3">
                {/* Main content layout */}
                <div className="d-flex flex-sm-row py-md-0 py-2 flex-column justify-content-between align-items-center position-relative z-3">
                    {/* Character with gun image */}
                    <div className="d-flex w-100">
                        <img

                            src={heroCharacter}
                            style={{
                                transform: "scaleX(-1)"
                            }}
                            alt="character with gun"
                            className="character_with_gun_image"
                            loading="lazy"
                        />
                        {/* BCoin icons container */}
                        <div className="d-flex justify-content-between align-items-center mx-lg-5 mx-4 hero_bicon_parent w-100 hero_b_coin_parent">
                            <span className="b_coin">
                                <BCoin />
                            </span>
                            <span className="b_coin">
                                <BCoin />
                            </span>
                            <span className="b_coin">
                                <BCoin />
                            </span>
                            <span className="b_coin d-none d-md-block">
                                <BCoin />
                            </span>
                        </div>
                    </div>
                    {/* Blinking text */}
                    <p className="text_yellow fw-normal pt-sm-0 pt-2 lh-normal text-end text-md-start mb-0 fs_xl me-sm-0 me-5 w-100 blinky_text">
                        Blinky Blast
                    </p>
                </div>
                {/* Description */}
                <div className="pt-sm-5 pt-4 pb-md-0 pb-3 me-lg-1">
                    <p className="mt-lg-2 pt-lg-4 me-lg-5 pe-lg-4 fs_md fw-normal leading_180 mb-0 text_yellow">
                        Blinky has a blicky, Blinky is the meme, Blinky reps memes, Blinky
                        got no road map, Blinky got no presale, Blinky got no tax, Blinky
                        blasts.
                    </p>
                </div>
                {/* Hero character image */}
                <div className="d-flex justify-content-end h-100 pt-lg-5 pb-xl-5 my-3 mb-md-0 mb-xl-5">
                    <img
                        src={heroCharacter}
                        alt="hero character"
                        className="hero_character_img"
                        loading="lazy"
                    />
                </div>
            </div>
            {/* Overlay layer */}
            <div className="hero_animation_overlay ms-auto position-absolute overlay_position  end-0"></div>
        </div>
    );
};

export default Hero;
