import "./App.css";
import dots from "./assets/images/svg/bg_bicons.svg";
import BackgroundMusic from "./components/BackgroundMusic";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import Tokenomics from "./components/Tokenomics";
import Video from "./components/Video";
import BackToTop from "./components/common/BackToTop";
import Lottie from "react-lottie-player";
import backgroundGif from "./assets/gif/background_gif.json";

function App() {
    return (
        <div className="bg-black overflow-hidden position-relative">
            <Lottie
                loop
                animationData={backgroundGif}
                speed={0.4}
                play
                className="bg_lottie"
            />
           {/* <img className="w-100 position-absolute bg_dots" src={dots} alt="dots" />*/}
           {/* <img
                className="w-100 position-absolute bg_dots_mobile d-xl-none"
                src={dots}
                alt="dots"
            />*/}
            <BackToTop />
            {/*<BackgroundMusic />*/}
            <NavBar />
            <Hero />
            <Video />
            <Tokenomics />
            <Footer />
        </div>
    );
}

export default App;
